<template>
  <div>
    <el-form
      ref="nodeForm"
      :rules="rules"
      label-position="top"
      label-width="100px"
      :model="nodeToBind"
      v-loading="!nodeLoading"
    >
      <el-form-item prop="node_name" :label="__('Name')">
        <el-input v-model="nodeToBind.node_name"></el-input>
      </el-form-item>

      <el-tabs v-model="activeTab" class="tabs" style="font-size: 1.2em;">
        <el-tab-pane :label="__('Form')" name="form">
          <el-form-item
            prop="open_form_node.data.form_type_id"
            :label="__('Form Type')"
          >
            <el-input
              v-if="isTaskReadOnly"
              :placeholder="
                this.nodeToBind.open_form_node.data.form_type_model ===
                'Custom Form'
                  ? this.nodeToBind.open_form_node.data.form_type.data
                      .form_type_name
                  : this.nodeToBind.open_form_node.data.form_type.data.oft_name
              "
              disabled
            ></el-input>

            <el-select
              v-if="!isTaskReadOnly"
              v-model="form_type_obj_temp"
              style="width: 100%;"
              @change="formTypeIdSelection($event)"
              :placeholder="__('Please select a formtype')"
              default-first-option
              value-key="id"
              filterable
              ref="formTypeElSelect"
            >
              <el-option-group
                v-for="(open_form_type, drop_down_label) in groupedFormType"
                :key="drop_down_label"
                :label="drop_down_label"
              >
                <el-option
                  v-for="(item, index) in open_form_type"
                  :label="item.oft_name"
                  :value="item"
                  :key="index"
                >
                  <span style="float: left; font-size: 13px; color: #181F29;">{{
                    item.oft_name
                  }}</span>
                  <span
                    style="float: right; color: #8492a6; font-size: 13px;  text-transform: capitalize;"
                    >{{
                      item.nlp_engine === "dialogflow_cx"
                        ? "Dialogflow CX"
                        : item.nlp_engine
                    }}</span
                  >
                </el-option>
              </el-option-group>
            </el-select>
          </el-form-item>
          <el-form-item
            prop="open_form_node.data.provider"
            :label="__('Provider')"
            v-if="showProviderField"
          >
            <el-radio-group
              v-model="nodeToBind.open_form_node.data.provider"
              @change="handleChangeProvider"
            >
              <el-radio-button label="lumenvox">Lumenvox</el-radio-button>
              <el-radio-button label="google">Google</el-radio-button>
            </el-radio-group>
          </el-form-item>

          <el-form-item
            prop="open_form_node.data.form_language_id"
            :label="__('Language')"
            v-if="showReturnValuesTable"
          >
            <el-select
              v-model="selectedLanguageId"
              style="width: 100%;"
              :placeholder="__('Please select a language')"
              default-first-option
              filterable
              clearable
              :loading-text="__('fetching languages')"
              :no-data-text="noText"
            >
              <el-option
                v-for="(item, index) in filteredLanguages"
                :label="item.language_name"
                :value="item.language_id"
                :key="index"
              />
            </el-select>
          </el-form-item>
          <el-form-item
            v-if="isDynamicForm"
            :label="__('Source')"
            prop="open_form_node.data.property_rules.data.source"
          >
            <expression-input
              v-model="dynamicFormSource"
              :complex-variables="complexVariables"
              :placeholder="sourcePlaceholder"
              @edit-input="displaySourceExpressionBuilderModal = true"
            ></expression-input>
          </el-form-item>
          <el-form-item
            prop="open_form_node.data.form_timezone"
            :label="__('Timezone')"
            v-if="checkNlpEngineType === 'lex'"
          >
            <timezone-dropdown
              :default_timezone="nodeToBind.open_form_node.data.form_timezone"
              @timezone-changed="handleTimezoneChange"
            />
          </el-form-item>

          <default-form-types
            :formType="getFormTypeName"
            :value="builtInFormTypeFormParametersValue"
            v-if="
              checkNlpEngineType === 'builtin' && showDefaultFormTypesComponent
            "
            @update-value="updateBuiltInFormParameters($event)"
          >
          </default-form-types>

          <el-form-item
            label="temp"
            prop="open_form_node.data.user_input_nodes_setting.data.form_parameters"
            v-if="
              checkNlpEngineType === 'builtin' &&
                getFormTypeName === 'Alpha Numeric'
            "
          >
            <div slot="label">
              <span slot="label" style="margin-right: 5px;"
                >{{ __("Rules") }}
              </span>
              <el-popover placement="top" width="400" trigger="click">
                <span v-html="popOverContent"></span>
                <el-button
                  slot="reference"
                  type="text"
                  size="mini"
                  style="color: #4db3f6;"
                >
                  {{ __("(example rules)") }}
                </el-button>
              </el-popover>
            </div>
            <alpha-numeric
              :value="builtInFormTypeFormParametersValue"
              :formType="getFormTypeName"
              @input="updateBuiltInFormParameters($event)"
            >
            </alpha-numeric>
          </el-form-item>
          <note
            :formType="getFormTypeName"
            v-if="checkNlpEngineType === 'builtin'"
          ></note>

          <el-form-item
            prop="open_form_node.data.variable_rules"
            :label="__('Return Values')"
            v-if="showReturnValuesTable && isReturnValuesNotEmpty"
          >
            <el-table
              :data="getVariableRules"
              max-height="250"
              style="width: 100%"
              row-class-name="return-values"
            >
              <el-table-column prop="return_value_name" :label="__('Value')">
              </el-table-column>
              <el-table-column
                prop="variable_id"
                :label="__('Assign the value to variable')"
              >
                <template slot-scope="scope">
                  <create-or-select
                    :items="singleValuedAndSecureVariables"
                    label="variable_name"
                    value="variable_id"
                    :current_select="currentSelection(scope.row) || ''"
                    :placeholder="__('Variable name (optional)')"
                    :new-item-message="__('new variable')"
                    @change="handleChange($event)(scope.row)"
                  />
                </template>
              </el-table-column>
              <el-table-column
                :label="__('Default Value')"
                prop="default_value"
              >
                <template slot-scope="scope">
                  <!-- todo  Media controller cannot omit invalid keys in the response for open form nodes as on 28th March 2022
                   so no matter the default value is provided, it's never used if the json path is incorrect under the context of queryResult.
                   to be fixed by STUD-2318 in media controller
                   -->
                  <input-variable-popper
                    v-model="scope.row.default_value"
                    :is-text-area="false"
                    force-reinitialize
                    :placeholder="__('Default Value')"
                    :include-secure-variables="false"
                  ></input-variable-popper>
                </template>
              </el-table-column>
              <el-table-column
                class-name="row-message"
                width="100px"
                prop="msg"
              />
            </el-table>
          </el-form-item>
        </el-tab-pane>
        <el-tab-pane :label="__('Text-to-Speech')" name="tts_settings">
          <el-form-item
            prop="open_form_node.data.user_input_nodes_setting.data.prompt_text"
            :label="__('Prompt')"
          >
            <input-variable-popper
              :value="promptText"
              is-content-editable
              @input="updateText('prompt_text', $event)"
              :is-text-area="false"
              include-prompts
              include-audio-variables
              :include-secure-variables="false"
              include-payments
              :ats="getOptionsInitiators"
              popper-class="prompt-node-popper"
              class="promptEditor"
              scroll-ref=".node-modal-open_form"
            />
            <audio-player
              class="audio-player"
              :disabled="!promptText"
              @get-preview="
                generateAudio(
                  'promptText',
                  'generatingAudioForPrompt',
                  'promptAudioFile',
                  'promptAtAudioFileCreation'
                )
              "
              :show-reload="promptContentChanged"
              :generating-audio="generatingAudioForPrompt"
              :file="promptAudioFile"
            />
          </el-form-item>

          <el-form-item
            prop="open_form_node.data.user_input_nodes_setting.data.fallback_prompt_text"
            :label="__('Fallback Prompt')"
          >
            <input-variable-popper
              :value="fallbackPromptText"
              is-content-editable
              @input="updateText('fallback_prompt_text', $event)"
              :is-text-area="false"
              include-prompts
              include-audio-variables
              :include-secure-variables="false"
              include-payments
              :ats="getOptionsInitiators"
              popper-class="prompt-node-popper"
              class="promptEditor"
              scroll-ref=".node-modal-open_form"
            />
            <audio-player
              class="audio-player"
              :disabled="!fallbackPromptText"
              @get-preview="
                generateAudio(
                  'fallbackPromptText',
                  'generatingAudioForFallbackPrompt',
                  'fallbackPromptAudioFile',
                  'fallbackPromptAtAudioFileCreation'
                )
              "
              :show-reload="fallbackPromptContentChanged"
              :generating-audio="generatingAudioForFallbackPrompt"
              :file="fallbackPromptAudioFile"
            />
          </el-form-item>
          <el-form-item
            prop="open_form_node.data.user_input_nodes_setting.data.no_input_prompt_text"
            :label="__('No Input Prompt')"
          >
            <input-variable-popper
              :value="noInputPromptText"
              is-content-editable
              @input="updateText('no_input_prompt_text', $event)"
              :is-text-area="false"
              include-prompts
              include-audio-variables
              :include-secure-variables="false"
              include-payments
              :ats="getOptionsInitiators"
              popper-class="prompt-node-popper"
              class="promptEditor"
              scroll-ref=".node-modal-open_form"
            />
            <audio-player
              class="audio-player"
              :disabled="!noInputPromptText"
              @get-preview="
                generateAudio(
                  'noInputPromptText',
                  'generatingAudioForNoInputPrompt',
                  'noInputPromptAudioFile',
                  'noInputPromptAtAudioFileCreation'
                )
              "
              :show-reload="noInputPromptContentChanged"
              :generating-audio="generatingAudioForNoInputPrompt"
              :file="noInputPromptAudioFile"
            />
          </el-form-item>
          <el-form-item
            prop="open_form_node.data.user_input_nodes_setting.data.no_match_prompt_text"
            :label="__('No Match Prompt')"
          >
            <input-variable-popper
              :value="noMatchPromptText"
              is-content-editable
              @input="updateNoMatchPromptText($event)"
              :is-text-area="false"
              include-prompts
              include-audio-variables
              :include-secure-variables="false"
              include-payments
              :ats="getOptionsInitiators"
              popper-class="prompt-node-popper"
              class="promptEditor"
              scroll-ref=".node-modal-open_form"
            />
            <audio-player
              class="audio-player"
              :disabled="!noMatchPromptText"
              @get-preview="
                generateAudio(
                  'noMatchPromptText',
                  'generatingAudioForNoMatchPrompt',
                  'noMatchPromptAudioFile',
                  'noMatchPromptAtAudioFileCreation'
                )
              "
              :show-reload="noMatchPromptContentChanged"
              :generating-audio="generatingAudioForNoMatchPrompt"
              :file="noMatchPromptAudioFile"
            />
          </el-form-item>
        </el-tab-pane>
        <el-tab-pane
          :label="__('Phrase Hints')"
          name="phrase_hints"
          :class="{ hide: activeTab !== 'phrase_hints' }"
          v-show="checkNlpEngineType === 'dialogflow'"
        >
          <el-form-item
            prop="open_form_node.data.phrase_hints"
            :label="__('Phrase Hints')"
          >
            <div class="phraseHintEditor">
              <input-variable-popper
                id="openFormPhraseHintInput"
                :value="phraseHints"
                v-permit="allowedRegex"
                is-text-area
                :ats="['{{']"
                popper-class="prompt-node-popper"
                @input="updatePhraseHints($event)"
                scroll-ref=".node-modal-open_form"
                :include-secure-variables="false"
                force-reinitialize
              />
            </div>
          </el-form-item>
          <el-form-item :label="__('Choose Datastore for Phrase Hints')">
            <el-select
              v-model="nodeToBind.open_form_node.data.phrase_hints_ds_id"
              style="width: 100%;"
              @change="onDataStoreChange"
              :loading="dataStoresLoading"
              :placeholder="placeHolderDS"
              default-first-option
              filterable
              clearable
            >
              <el-option
                v-for="(item, index) in dataStores"
                :label="item.display_name"
                :value="item.data_store_id"
                :key="index"
              />
            </el-select>
          </el-form-item>
          <el-form-item :label="__('Choose Datastore column for Phrase Hints')">
            <el-select
              v-model="nodeToBind.open_form_node.data.ds_phrase_hints_column"
              style="width: 100%;"
              :placeholder="__('Select phrase hints column from Datastore')"
              default-first-option
              filterable
              clearable
            >
              <el-option
                v-for="(item, index) in dsColumnList('all')"
                :label="item.col_name"
                :value="item.col_name"
                :key="index"
              />
            </el-select>
          </el-form-item>
          <el-form-item :label="__('Datastore boost column')">
            <el-select
              class="w-full"
              value-key="key"
              v-model="nodeToBind.open_form_node.data.ds_boost_column"
              style="width: 100%;"
              :placeholder="__('Select boost column from Datastore')"
              filterable
              :remote-method="filterList"
              default-first-option
              remote
              clearable
              @visible-change="handleDSBoostColumnDropdownVisibleChange"
            >
              <!--              @clear="initializeBoostColumnListAfterClear"-->

              <div class="infinite-list" v-infinite-scroll="load">
                <el-option-group
                  v-for="group in dsColumnWithVariables"
                  :key="group.label"
                  :label="group.label"
                >
                  <el-option
                    v-for="item in group.options"
                    :key="`${group.label}_${item.column_id}`"
                    :label="item.column_name"
                    :value="item.column_id"
                  >
                  </el-option>
                </el-option-group>
              </div>
            </el-select>
          </el-form-item>
        </el-tab-pane>
        <el-tab-pane :label="__('Speech Recognizer')" name="sr_settings">
          <el-scrollbar :native="false">
            <!--            <div style="max-height: 90vh">-->
            <el-form-item
              v-if="nlpEngineByGoogle"
              prop="open_form_node.data.user_input_nodes_setting.data.asr_model"
              :label="__('Speech Recognition Model')"
            >
              <speech-recognition-model
                ref="speech-recognition-model"
                :asr-model="
                  nodeToBind.open_form_node.data.user_input_nodes_setting.data
                    .asr_model
                "
                :asr-model-filter="availableAsrModels"
                @update-asr-model="updateAsrModel"
              >
              </speech-recognition-model>
            </el-form-item>
            <el-form-item
              :label="__('Assign transcribed text to variable')"
              v-if="
                checkNlpEngineType !== 'builtin' &&
                  currentProvider !== 'lumenvox'
              "
            >
              <create-or-select
                :items="singleValuedAndSecureVariables"
                label="variable_name"
                value="variable_id"
                :placeholder="__('Select or create a new variable')"
                :new-item-message="__('new variable')"
                :current_select="
                  currentSelectionTranscribedTextToVariable || ''
                "
                @change="handleChangeTranscribedTextToVariable"
              />
              <el-col :span="4">
                <span
                  class="row-message"
                  v-if="newVariableForTranscribedTextVar"
                  >{{ __("new variable") }}</span
                >
              </el-col>
            </el-form-item>
            <el-form-item
              :label="__('Assign confidence score to variable')"
              v-if="
                checkNlpEngineType !== 'builtin' &&
                  currentProvider !== 'lumenvox'
              "
            >
              <create-or-select
                :items="singleValuedAndSecureVariables"
                label="variable_name"
                value="variable_id"
                :placeholder="__('Select or create a new variable')"
                :new-item-message="__('new variable')"
                :current_select="currentSelectionConfidenceToVariable || ''"
                @change="handleChangeConfidenceToVariable"
              />
              <el-col :span="4">
                <span class="row-message" v-if="newVariableForConfidenceVar">{{
                  __("new variable")
                }}</span>
              </el-col>
            </el-form-item>
            <el-form-item
              :label="__('Assign recording file to audio variable')"
              v-if="
                checkNlpEngineType !== 'builtin' &&
                  currentProvider !== 'lumenvox'
              "
            >
              <create-or-select
                :items="audioVariables"
                label="variable_name"
                value="variable_id"
                :placeholder="__('Select or create a new audio variable')"
                :new-item-message="__('new variable')"
                :current_select="
                  currentSelectionRecordFileToAudioVariable || ''
                "
                @change="handleChangeRecordFileToAudioVariable"
              />
              <el-col :span="4">
                <span class="row-message" v-if="newVariableForAudioVar">{{
                  __("new audio variable")
                }}</span>
              </el-col>
            </el-form-item>
            <el-form-item
              prop="open_form_node.data.user_input_nodes_setting.data.barge_in"
              :label="__('Speech Controls')"
            >
              <el-checkbox
                v-model="
                  nodeToBind.open_form_node.data.user_input_nodes_setting.data
                    .barge_in
                "
                style="margin-left: 2px;"
                >{{ __("Barge In") }}
              </el-checkbox>
              <el-checkbox
                v-model="
                  nodeToBind.open_form_node.data.user_input_nodes_setting.data
                    .dtmf_only
                "
                style="margin-left: 2px;"
                v-if="
                  checkNlpEngineType === 'custom' ||
                    checkNlpEngineType === 'builtin'
                "
                >{{ __("Disable Voice Input") }}</el-checkbox
              >
            </el-form-item>
            <el-form-item
              prop="open_form_node.data.user_input_nodes_setting.data.confidence_level"
              :label="__('Minimum Transcription Confidence Score')"
            >
              <el-input-number
                v-model="
                  nodeToBind.open_form_node.data.user_input_nodes_setting.data
                    .confidence_level
                "
                :precision="2"
                :min="0.0"
                :step="0.05"
                :max="1"
                :step-strictly="false"
              ></el-input-number>
            </el-form-item>
            <el-form-item
              prop="open_form_node.data.user_input_nodes_setting.data.interdigit_timeout"
              :label="__('Interdigit Timeout (Seconds)')"
              v-if="
                checkNlpEngineType === 'custom' ||
                  checkNlpEngineType === 'builtin'
              "
            >
              <div class="asr-description">
                {{ __("DTMF Only") }}
              </div>
              <el-input-number
                v-model="
                  nodeToBind.open_form_node.data.user_input_nodes_setting.data
                    .interdigit_timeout
                "
                :precision="1"
                :min="0.5"
                :step="0.5"
                :max="10"
                :step-strictly="true"
              ></el-input-number>
            </el-form-item>
          </el-scrollbar>
        </el-tab-pane>
        <el-tab-pane :label="__('Event Handler')" name="event_handler">
          <el-scrollbar :native="false">
            <div style="max-height: 90vh">
              <el-form-item
                prop="open_form_node.data.user_input_nodes_setting.data.no_input_canvas_id"
              >
                <EventHandlers
                  :event-handler-canvas="noInputEventHandlerCanvas"
                  :count="
                    nodeToBind.open_form_node.data.user_input_nodes_setting.data
                      .no_input_count
                  "
                  :eventHandlerLabel="__('No Input Event Handler')"
                  :event-handler-place-holder="
                    __('Select or create a no input event handler canvas')
                  "
                  @update-event-handler="setNoInputEventHandlerCanvas($event)"
                  @update-event-handler-count="
                    setNoInputEventHandlerCount($event)
                  "
                  cssClass="is-required"
                ></EventHandlers>
              </el-form-item>
              <el-form-item
                prop="open_form_node.data.user_input_nodes_setting.data.no_match_canvas_id"
              >
                <EventHandlers
                  :event-handler-canvas="noMatchEventHandlerCanvas"
                  :count="
                    nodeToBind.open_form_node.data.user_input_nodes_setting.data
                      .no_match_count
                  "
                  :eventHandlerLabel="__('No Match Event Handler')"
                  :event-handler-place-holder="
                    __('Select or create a no match event handler canvas')
                  "
                  @update-event-handler="setNoMatchEventHandlerCanvas($event)"
                  @update-event-handler-count="
                    setNoMatchEventHandlerCount($event)
                  "
                  cssClass="is-required"
                ></EventHandlers>
              </el-form-item>
            </div>
          </el-scrollbar>
        </el-tab-pane>
        <el-tab-pane
          :label="__('NLP Settings')"
          name="nlp_settings"
          v-if="showAdvancedAsrTab"
        >
          <el-scrollbar :native="false">
            <div style="max-height: 90vh">
              <el-form-item
                prop="open_form_node.data.user_input_nodes_setting.data.form_parameters"
                :label="__('Query Parameters')"
                v-if="
                  checkNlpEngineType === 'dialogflow' ||
                    checkNlpEngineType === 'dialogflow_cx'
                "
              >
                <div class="phraseHintEditor">
                  <input-variable-popper
                    :value="formParameters"
                    is-text-area
                    :ats="['{{']"
                    popper-class="prompt-node-popper"
                    @input="updateFormParameters($event)"
                    scroll-ref=".node-modal-open_form"
                    :include-secure-variables="false"
                  />
                </div>
              </el-form-item>
              <el-form-item
                prop="open_form_node.data.language_variable_id"
                :label="__('Language Variable')"
              >
                <language-variable
                  v-model="languageVariable"
                  @lang-var-changed="handleLanguageVarChanged"
                />
              </el-form-item>
            </div>
          </el-scrollbar>
        </el-tab-pane>
        <el-tab-pane
          :label="__('Advanced ASR Settings')"
          name="advanced_asr_settings"
        >
          <el-scrollbar :native="false">
            <div style="max-height: 90vh">
              <advanced-speech-parameters
                :nlp-engine="this.form_type_obj_temp.nlp_engine"
                :provider="currentProvider"
                :key="advanced_asr_rerender_key"
                :form-type-id="
                  nodeToBind.open_form_node.data.form_type_model +
                    nodeToBind.open_form_node.data.form_type_id
                "
                :speech-complete-timeout="
                  nodeToBind.open_form_node.data.user_input_nodes_setting.data
                    .speech_complete_timeout
                "
                :speech-incomplete-timeout="
                  nodeToBind.open_form_node.data.user_input_nodes_setting.data
                    .speech_incomplete_timeout
                "
                :no-input-timeout="
                  nodeToBind.open_form_node.data.user_input_nodes_setting.data
                    .no_input_timeout
                "
                :speech-start-timeout="
                  nodeToBind.open_form_node.data.user_input_nodes_setting.data
                    .speech_start_timeout
                "
                :inter-result-timeout="
                  nodeToBind.open_form_node.data.user_input_nodes_setting.data
                    .inter_result_timeout
                "
                :barge-in-sensitivity="
                  nodeToBind.open_form_node.data.user_input_nodes_setting.data
                    .barge_in_sensitivity
                "
                :auto-punctuation="
                  nodeToBind.open_form_node.data.user_input_nodes_setting.data
                    .auto_punctuation
                "
                :profanity-filter="
                  nodeToBind.open_form_node.data.user_input_nodes_setting.data
                    .profanity_filter
                "
                :single-utterance="
                  nodeToBind.open_form_node.data.user_input_nodes_setting.data
                    .single_utterance
                "
                :node-type="'openFormNode'"
                @update-nlp-parameter="updateNlpParameters"
              >
              </advanced-speech-parameters>
            </div>
          </el-scrollbar>
        </el-tab-pane>
        <el-tab-pane
          :label="__('Confirmation')"
          name="confirm_settings"
          v-if="shouldEnableConfirmationTab"
        >
          <speech-input-confirmation
            @update-max-number-of-input-count="setMaxNumberOfInputCount($event)"
            :showNoInputTimeout="true"
            :taskId="task_id"
            :documentRule="inputNodeSetting"
            scroll-ref=".node-modal-open_form"
          />
        </el-tab-pane>
      </el-tabs>
    </el-form>
    <expression-builder-dialog
      v-if="displaySourceExpressionBuilderModal"
      v-model="dynamicFormSource"
      :show-expression-builder="displaySourceExpressionBuilderModal"
      @input="handleCancelSourceExpressionBuilderModal"
      @cancel="handleCancelSourceExpressionBuilderModal"
      @close="handleCancelSourceExpressionBuilderModal"
    />
  </div>
</template>

<script>
import BaseNode from "@/views/build/callflow/components/node-type-forms/BaseNode";
import GenerateAudio from "@/views/build/callflow/components/GenerateAudio";
import { NODE_TYPES } from "@/constants/nodes";
import { mapActions, mapGetters, mapState } from "vuex";
import _ from "lodash";
import CreateOrSelect from "@/views/build/callflow/components/node-type-forms/components/CreateOrSelect";
import InputVariablePopper from "@/views/build/callflow/components/node-type-forms/components/InputVariablePopper";
import AudioPlayer from "@/components/AudioPlayer";
import { filterRowsIfSomeKeyValueIsAbsent } from "@/utils/collection";
import TimezoneDropdown from "@/components/TimezoneDropdown.vue";
import DefaultFormTypes from "@/components/DefaultFormTypes";
import AlphaNumeric from "@/components/AlphaNumeric";
import Note from "@/components/Note";
import { phraseHintsRegex } from "@/utils/regex";
import EventHandlers from "@/views/build/callflow/components/node-type-forms/components/EventHandlers";
import AdvancedSpeechParameters from "@/components/AdvancedSpeechParameters.vue";
import SpeechInputConfirmation from "@/views/build/callflow/components/audioInputConfirmation/ConfirmationElement.vue";
import {
  getAsrModelsFromLanguage
  // getDefaultAsrModelFromLanguage
} from "@/utils/AsrModels";
import LanguageVariable from "@/views/build/callflow/components/node-type-forms/components/LanguageVariable";
import ExpressionInput from "@/views/build/callflow/components/expression-builder/ExpressionInput";
import ExpressionBuilderDialog from "@/views/build/callflow/components/expression-builder/ExpressionBuilderDialog";
import { getComplexVariables } from "@/api/variables";
import { EventBus } from "@/EventBus";
import speechRecognitionModel from "@/views/build/callflow/components/node-type-forms/components/SpeechRecognitionModel";
import {
  NLP_WATSON,
  NLP_OPENAI,
  NLP_AZURE_OPENAI
} from "@/constants/openFormType";
import { eventHandlerNameValidation } from "@/utils/formValidationRules";

// import SchemaGenerator from "@/utils/SchemaGenerator";
const variableRuleInitialize = {
  rule_value: "",
  variable_name: "",
  variable_id: -1,
  default_value: "",
  array_variable: false,
  variable_type: "audio"
};

const DYNAMIC_FORM = "Dynamic Form";
const OPEN_FORM = "Open Form";
const CUSTOM_FORM = "Custom Form";
const eventHandlerCanvasInitialize = {
  canvas_name: "",
  canvas_id: -1,
  msg: ""
};
const initOpenFormNode = {
  node_type: NODE_TYPES.OPEN_FORM.NODE_TYPE,
  open_form_node: {
    data: {
      form_type_model: "",
      form_type_id: null,
      form_language_id: null,
      language_variable_id: null,
      phrase_hints: "",
      phrase_hints_ds_id: null,
      ds_phrase_hints_column: null,
      ds_boost_column: null,
      form_timezone: "",
      provider: null,
      variable_rules: {
        data: []
      },
      user_input_nodes_setting: {
        data: {
          barge_in: false,
          dtmf_only: false,
          no_match_count: 2,
          no_input_count: 2,
          prompt_text: "",
          fallback_prompt_text: "",
          no_input_prompt_text: "",
          no_input_timeout: 5000,
          no_match_prompt_text: "",
          form_parameters: "",
          confidence_level: 0.5,
          interdigit_timeout: 2,
          no_input_canvas_id: _.cloneDeep(eventHandlerCanvasInitialize),
          no_match_canvas_id: _.cloneDeep(eventHandlerCanvasInitialize),
          override_form_nlp_params: false,
          speech_complete_timeout: 1000,
          speech_incomplete_timeout: 20000,
          speech_start_timeout: 50,
          inter_result_timeout: 100,
          barge_in_sensitivity: 0.5,
          auto_punctuation: false,
          profanity_filter: false,
          single_utterance: true,
          asr_model: ""
        }
      },
      property_rules: {
        data: {
          source: ""
        }
      }
    }
  }
};

export default {
  components: {
    LanguageVariable,
    CreateOrSelect,
    InputVariablePopper,
    AudioPlayer,
    TimezoneDropdown,
    DefaultFormTypes,
    AlphaNumeric,
    Note,
    EventHandlers,
    AdvancedSpeechParameters,
    SpeechInputConfirmation,
    ExpressionInput,
    ExpressionBuilderDialog,
    speechRecognitionModel
  },
  mixins: [BaseNode, GenerateAudio],
  data() {
    const validateNoInputEventHandler = (rule, value, callback) => {
      eventHandlerNameValidation(value, callback, this, "input");
    };
    const validateNoMatchEventHandler = (rule, value, callback) => {
      eventHandlerNameValidation(value, callback, this, "match");
    };
    const validateSource = (rule, value, callback) => {
      if (!value) {
        callback(__("Source is required"));
      } else if (_.isEmpty(JSON.parse(value))) {
        callback(__("Source is required"));
      } else {
        callback();
      }
    };
    const validateQueryParams = (rule, value, cb) => {
      if (!value) {
        if (
          this.checkNlpEngineType === "builtin" &&
          this.getFormTypeName === "Alpha Numeric"
        ) {
          cb(__("Please add rules"));
        } else {
          cb();
        }
      } else if (this.hasInvalidVariable(value)) {
        cb(__("Invalid variables found in Query Params"));
      } else {
        cb();
      }
    };
    return {
      initializing: false,
      preText: "",
      advanced_asr_rerender_key: 0,
      preTextToLanguagesMap: {
        "Amazon Lex": [],
        "Google dialogflow": [],
        Lumenvox: [],
        "Google Cloud Speech to Text": []
      },
      rules: {
        "open_form_node.data.form_type_id": [
          {
            required: true,
            message: __("Please select a formtype"),
            trigger: "blur"
          }
        ],
        "open_form_node.data.form_language_id": [
          {
            required: true,
            message: __("Please select a language"),
            trigger: "change"
          }
        ],
        "open_form_node.data.user_input_nodes_setting.data.asr_model": [
          {
            required: true,
            message: __("Please select a speech recognition model"),
            trigger: "change"
          }
        ],
        "open_form_node.data.user_input_nodes_setting.data.form_parameters": [
          {
            validator: validateQueryParams,
            trigger: "blur"
          }
        ],
        "open_form_node.data.user_input_nodes_setting.data.prompt_text": [
          {
            required: true,
            message: __("Please add prompt text"),
            trigger: "blur"
          }
        ],

        "open_form_node.data.user_input_nodes_setting.data.fallback_prompt_text": [
          {
            required: true,
            message: __("Please add fallback prompt text"),
            trigger: "blur"
          }
        ],
        "open_form_node.data.user_input_nodes_setting.data.no_input_canvas_id": [
          {
            validator: validateNoInputEventHandler,
            trigger: "change"
          }
        ],
        "open_form_node.data.user_input_nodes_setting.data.no_match_canvas_id": [
          {
            validator: validateNoMatchEventHandler,
            trigger: "change"
          }
        ],
        "open_form_node.data.property_rules.data.source": [
          {
            required: true,
            validator: validateSource,
            trigger: "blur"
          }
        ]
      },
      variableRuleInitialize: _.cloneDeep(variableRuleInitialize),
      variableRulesBkup: [],
      currentSelectionIndex: 0,
      selectedReturnValues: [],
      activeTab: "form",
      promptAudioFile: "",
      fallbackPromptAudioFile: "",
      noInputPromptAudioFile: "",
      noMatchPromptAudioFile: "",
      generatingAudioForPrompt: false,
      generatingAudioForFallbackPrompt: false,
      generatingAudioForNoInputPrompt: false,
      generatingAudioForNoMatchPrompt: false,
      promptAtAudioFileCreation: "",
      fallbackPromptAtAudioFileCreation: "",
      noInputPromptAtAudioFileCreation: "",
      noMatchPromptAtAudioFileCreation: "",
      form_type_obj_temp: {},
      disable_speech_input: false,
      allowRender: [
        "Custom Digit String",
        "Scale",
        "Custom Alpha Numeric String"
      ],
      currentTab: "",
      openFormNodeResponse: {
        ws_response_data: {
          raw_transcribe: "",
          confidence_score: "",
          additional_information: {
            confusables: [{}]
          }
        },
        ws_response_meta: []
      },
      allowedRegex: phraseHintsRegex,
      newVariableForAudioVar: 0,
      newVariableForTranscribedTextVar: 0,
      newVariableForConfidenceVar: 0,
      transcribeTextRuleValue: "sys.open_form_transcribed_text_var",
      confidenceRuleValue: "sys.open_form_confidence_var",
      userUtteranceAudioRuleValue: "sys.open_form_audio_var",
      transcribeTextObj: {},
      confidenceObj: {},
      userUtteranceAudioObj: {},
      tabStructure: {
        form: [
          "open_form_node.data.form_type_id",
          "open_form_node.data.form_language_id"
        ],
        tts_settings: [
          "open_form_node.data.user_input_nodes_setting.data.prompt_text",
          "open_form_node.data.user_input_nodes_setting.data.fallback_prompt_text"
        ],
        sr_settings: [],
        event_handler: [
          "open_form_node.data.user_input_nodes_setting.data.no_input_canvas_id",
          "open_form_node.data.user_input_nodes_setting.data.no_match_canvas_id"
        ],
        add_settings: ["open_form_node.data.language_variable_id"]
      },
      noDataTextDS: __("Couldn't find a Datastore in your account"),
      placeHolderDS: __("Please select a Datastore for phrase hints"),
      $variablesList: [],
      $pendingVariablesList: [],
      $completeVariablesList: [],
      dsBoostColumnQuery: "",
      // eslint-disable-next-line
      sourcePlaceholder: __("Specify a source for the Dynamic Form using the expression builder"),
      displaySourceExpressionBuilderModal: false,
      complexVariables: [],
      invalidVariablesMap: {
        query_params: false
      }
    };
  },
  computed: {
    ...mapState("openFormTypes", {
      openFormTypesLoading: state => state.loading
    }),
    ...mapState("formTypes", {
      formTypes: state => state.formtypes,
      formTypesLoading: state => state.loading
    }),
    ...mapState("dynamicFormTypes", {
      dynamicFormTypes: state => state.dynamicformtypes,
      dynamicFormTypesLoading: state => state.loading
    }),
    ...mapState("prompts", {
      prompts: state => state.prompts,
      promptsLoading: state => state.loading
    }),
    ...mapState("asrLanguages", {
      asrLanguages: state => state.asrLanguages,
      asrLanguagesLoading: state => state.loading
    }),

    ...mapState("dataStores", {
      dataStores: state => state.dataStores,
      dataStoresLoading: state => state.loading
    }),
    ...mapState("nodeTypes", {
      accountRawNodes: state => state.accountRawNodes
    }),
    ...mapState("app", {
      selectedAccountId: state => state.selectedAccountId
    }),
    ...mapGetters("openFormTypes", {
      openFormTypes: "openformtypes"
    }),
    ...mapGetters("canvas", {
      getEventHandlerCanvasList: "getEventHandlerCanvasList",
      isTaskReadOnly: "isTaskReadOnly"
    }),
    ...mapGetters("variables", {
      arrayVariable: "arrayVariable",
      arrayVariableName: "arrayVariableName"
    }),
    inputNodeSetting: {
      get: function() {
        return _.get(
          this.nodeToBind.open_form_node.data,
          "user_input_nodes_setting",
          {}
        );
      },
      set: function(value) {
        this.$set(
          this.nodeToBind.open_form_node.data,
          "user_input_nodes_setting",
          value
        );
      }
    },

    selectedCustomFormType() {
      // formTypesCloned = _.cloneDeep(this.formTypes);
      return _.find(this.formTypes, {
        form_type_id: this.nodeToBind.open_form_node.data.form_type_id
      });
    },

    selectedOpenFormType() {
      // formTypesCloned = _.cloneDeep(this.openFormTypes);
      return _.find(this.openFormTypes, {
        open_form_type_id: this.nodeToBind.open_form_node.data.form_type_id
      });
    },

    noInputEventHandlerCanvas() {
      return this.nodeToBind.open_form_node.data.user_input_nodes_setting.data
        .no_input_canvas_id;
    },
    noMatchEventHandlerCanvas() {
      return this.nodeToBind.open_form_node.data.user_input_nodes_setting.data
        .no_match_canvas_id;
    },

    variablesOption() {
      return {
        label: "Variables",
        options: this.$data.$variablesList
      };
    },
    dsColumnWithVariables() {
      let columns = this.dsColumnList("all").filter(
        item => item.type === "integer"
      );
      if (this.dsBoostColumnQuery) {
        columns = _.filter(columns, column =>
          column.col_name.toLowerCase().includes(this.dsBoostColumnQuery)
        );
      }
      return [
        {
          label: __("Datastore Columns"),
          options: _.map(columns, column => {
            return {
              column_id: column.col_name,
              column_name: column.col_name,
              key: `ds_${column.col_name}`
            };
          })
        },
        this.variablesOption
      ];
      // return [];
    },
    selectedDataStore() {
      if (this.nodeToBind.open_form_node.data.phrase_hints_ds_id) {
        return _.find(this.dataStores, {
          data_store_id: this.nodeToBind.open_form_node.data.phrase_hints_ds_id
        });
      }
      return null;
    },
    dsColumnList() {
      return type => {
        let result = [];
        if (this.selectedDataStore) {
          if (type === "dtmf") {
            result = _.filter(
              this.selectedDataStore.collection_structure,
              function(chr) {
                return chr.type === "integer";
              }
            );
          } else {
            result = _.filter(
              this.selectedDataStore.collection_structure,
              function(chr) {
                return chr.type !== "audio";
              }
            );
          }
        }
        return result;
      };
    },
    languageVariable: {
      get: function() {
        return this.nodeToBind.open_form_node.data.language_variable_id || -1;
      },
      set: function(val) {
        this.$set(
          this.nodeToBind.open_form_node.data,
          "language_variable_id",
          val
        );
      }
    },
    promptText() {
      return this.nodeToBind.open_form_node.data.user_input_nodes_setting.data
        .prompt_text;
    },
    fallbackPromptText() {
      return this.nodeToBind.open_form_node.data.user_input_nodes_setting.data
        .fallback_prompt_text;
    },
    noInputPromptText() {
      return this.nodeToBind.open_form_node.data.user_input_nodes_setting.data
        .no_input_prompt_text;
    },
    noMatchPromptText() {
      return this.nodeToBind.open_form_node.data.user_input_nodes_setting.data
        .no_match_prompt_text;
    },
    phraseHints() {
      return this.nodeToBind.open_form_node.data.phrase_hints;
    },
    formParameters() {
      return this.nodeToBind.open_form_node.data.user_input_nodes_setting.data
        .form_parameters;
    },
    getOptionsInitiators() {
      return ["{{", "[["];
    },
    promptContentChanged() {
      return this.promptText !== this.promptAtAudioFileCreation;
    },
    fallbackPromptContentChanged() {
      return this.fallbackPromptText !== this.fallbackPromptAtAudioFileCreation;
    },
    noInputPromptContentChanged() {
      return this.noInputPromptText !== this.noInputPromptAtAudioFileCreation;
    },
    noMatchPromptContentChanged() {
      return this.noMatchPromptText !== this.noMatchPromptAtAudioFileCreation;
    },
    currentSelection() {
      return row => {
        const { variable_id, variable_name } = row;
        return variable_id === -1 ? variable_name : variable_id;
      };
    },
    getVariableRules: function() {
      return _.filter(
        this.isNodeSubmit
          ? this.variableRulesBkup
          : this.nodeToBind.open_form_node.data.variable_rules.data,
        function(v) {
          return (
            !_.startsWith(v.rule_value, "sys.") && v.variable_type !== "array"
          );
        }
      );
    },
    isReturnValuesNotEmpty: function() {
      return !_.isEmpty(this.getVariableRules);
    },

    // Merge Open formtypes and Custom formtypes data for the form type drop-down selection
    //  return Object
    groupedFormType() {
      let groupOpenForm = {};
      let groupCustomForm = {};
      let groupDynamicForm = {};

      if (!_.isEmpty(this.openFormTypes)) {
        let openFormTypes = this.openFormTypes.map(v => ({
          ...v,
          group: v.is_system ? __("System Open Form") : __("Open Form"),
          type: OPEN_FORM,
          drop_down_label: OPEN_FORM,
          id: _.toString(v.open_form_type_id).concat("_openform")
        }));
        openFormTypes = openFormTypes.filter(
          open_form_type =>
            open_form_type.nlp_engine !== NLP_WATSON &&
            open_form_type.nlp_engine !== NLP_OPENAI &&
            open_form_type.nlp_engine !== NLP_AZURE_OPENAI
        );

        openFormTypes = this.removeArchivedFormTypes(openFormTypes);
        groupOpenForm = _.mapValues(_.groupBy(openFormTypes, "group"));
      }
      if (!_.isEmpty(this.formTypes)) {
        let customFormTypes = this.formTypes.map(v => {
          let option_group_label = v.is_standard
            ? __("Built-In")
            : __("Custom");
          let label = v.is_standard ? __("Built-In") : __("Custom Form");
          return Object.assign(v, {
            oft_name: v.form_type_name,
            nlp_engine: option_group_label,
            type: CUSTOM_FORM,
            drop_down_label: label,
            id: _.toString(v.form_type_id).concat("_customform")
          });
        });

        customFormTypes = this.removeArchivedFormTypes(customFormTypes);

        groupCustomForm = _.mapValues(
          _.groupBy(customFormTypes, "drop_down_label")
        );
      }

      if (!_.isEmpty(this.dynamicFormTypes)) {
        let dynamicFormTypes = this.dynamicFormTypes.map(v => ({
          ...v,
          type: DYNAMIC_FORM,
          drop_down_label: DYNAMIC_FORM,
          oft_name: v.dft_name,
          nlp_engine: "custom",
          id: _.toString(v.dynamic_form_type_id).concat("_dynamicform")
        }));

        dynamicFormTypes = this.removeArchivedFormTypes(dynamicFormTypes);
        groupDynamicForm = _.mapValues(
          _.groupBy(dynamicFormTypes, "drop_down_label")
        );
      }

      return { ...groupCustomForm, ...groupOpenForm, ...groupDynamicForm };
    },

    checkNlpEngineType() {
      let selectedFormType = this.form_type_obj_temp;
      if (_.has(selectedFormType, "form_type_id")) {
        if (_.get(selectedFormType, "is_standard")) {
          return "builtin";
        }
        return "custom";
      }
      if (_.isEmpty(selectedFormType)) {
        return "custom";
      }
      return selectedFormType.nlp_engine;
    },
    currentProvider() {
      return !_.get(this.nodeToBind, "open_form_node.data.provider")
        ? "google"
        : this.nodeToBind.open_form_node.data.provider;
    },

    noText() {
      return (
        this.preText +
        __(" ASR languages are not provisioned in your business plan")
      );
    },

    isValidLumenvoxEngine() {
      return (
        this.checkNlpEngineType === "builtin" ||
        this.checkNlpEngineType === "custom"
      );
    },

    /**
     * Getter-setter for the ID of the selected language
     */
    selectedLanguageId: {
      get() {
        return _.get(this.nodeToBind, "open_form_node.data.form_language_id");
      },

      set(val) {
        this.$set(this.nodeToBind.open_form_node.data, "form_language_id", val);
      }
    },

    //  filters language based on the NLP engine selected
    //  return Array
    filteredLanguages() {
      return this.preTextToLanguagesMap[this.preText] || [];
    },
    // filteredLanguagesOld() {
    //   let asrLanguages = [];
    //   let self = this;
    //   if (this.checkNlpEngineType === "lex") {
    //     asrLanguages = _.filter(this.asrLanguages, [
    //       "language_provider",
    //       "amazon"
    //     ]);
    //     self.preText = "Amazon Lex";
    //   } else if (this.checkNlpEngineType === "dialogflow") {
    //     asrLanguages = _.filter(this.asrLanguages, function(o) {
    //       return (
    //         o.language_provider === "google" &&
    //         o.language_used_in.includes("dialogflow")
    //       );
    //     });
    //     self.preText = "Google dialogflow";
    //   } else if (
    //     this.currentProvider === "lumenvox" &&
    //     this.checkNlpEngineType === "builtin"
    //     // && this.getFormTypeName !== "Integer"
    //   ) {
    //     asrLanguages = _.filter(this.asrLanguages, [
    //       "language_provider",
    //       "lumenvox"
    //     ]);
    //     self.preText = "Lumenvox";
    //     // } else if (
    //     //   this.checkNlpEngineType === "builtin" &&
    //     //   this.getFormTypeName === "Integer"
    //     // ) {
    //     //   asrLanguages = _.filter(this.asrLanguages, lang => {
    //     //     console.log(lang.language_name);
    //     //     return (
    //     //       lang.language_provider === "lumenvox" &&
    //     //       lang.language_name.includes("English")
    //     //     );
    //     //   });
    //     //   //   self.preText = "Lumenvox";
    //   } else {
    //     asrLanguages = _.filter(this.asrLanguages, function(o) {
    //       return (
    //         o.language_provider === "google" &&
    //         o.language_used_in.includes("cloud_speech")
    //       );
    //     });
    //     self.preText = "Google Cloud Speech to Text";
    //   }
    //   if (!asrLanguages.length && !self.asrLanguagesLoading) {
    //     this.$message({
    //       type: "error",
    //       message: self.noText
    //     });
    //   }
    //   return asrLanguages;
    // },
    nodeLoading() {
      return (
        !this.initializing &&
        !this.openFormTypesLoading &&
        !this.asrLanguagesLoading &&
        !this.formTypesLoading &&
        !this.dynamicFormTypesLoading
      );
    },
    showReturnValuesTable() {
      return !_.isEmpty(this.form_type_obj_temp);
    },
    showProviderField() {
      return this.showReturnValuesTable && this.checkNlpEngineType === "custom";
    },
    getFormTypeName() {
      return _.get(this.form_type_obj_temp, "oft_name");
    },
    showDefaultFormTypesComponent() {
      return this.allowRender.includes(this.getFormTypeName);
    },
    currentSelectionRecordFileToAudioVariable() {
      const { variable_id, variable_name } = _.isEmpty(
        this.userUtteranceAudioObj
      )
        ? this.variableRuleInitialize
        : this.userUtteranceAudioObj;
      return variable_id === -1 ? variable_name : variable_id;
    },

    currentSelectionTranscribedTextToVariable() {
      const { variable_id, variable_name } = _.isEmpty(this.transcribeTextObj)
        ? this.variableRuleInitialize
        : this.transcribeTextObj;
      return variable_id === -1 ? variable_name : variable_id;
    },

    currentSelectionConfidenceToVariable() {
      const { variable_id, variable_name } = _.isEmpty(this.confidenceObj)
        ? this.variableRuleInitialize
        : this.confidenceObj;
      return variable_id === -1 ? variable_name : variable_id;
    },
    builtInFormTypeFormParametersValue() {
      let builtInFormParams = {};
      try {
        builtInFormParams = JSON.parse(
          this.nodeToBind.open_form_node.data.user_input_nodes_setting.data
            .form_parameters
        );
      } catch (e) {
        console.error("Form parameters does not contain valid json");
      }

      return _.isEmpty(builtInFormParams) ? {} : builtInFormParams;
    },

    /**
     * Check if we should enable the advanced ASR tab
     */
    showAdvancedAsrTab() {
      return (
        this.checkNlpEngineType !== "builtin" &&
        this.currentProvider !== "lumenvox"
      );
    },

    /**
     * Check if the confirmation tab should be enabled or not
     */
    shouldEnableConfirmationTab() {
      return this.nodeToBind.open_form_node.data.form_type_model !== OPEN_FORM;
    },

    /**
     * Get the available ASR models from the current selected CSTT language
     */
    availableAsrModels() {
      return getAsrModelsFromLanguage(
        this.filteredLanguages,
        this.selectedLanguageId,
        this.nodeToBind.open_form_node.data.provider
      );
    },

    /**
     * Check if the NLP engine is provided by google
     */
    nlpEngineByGoogle() {
      const selectedLanguage = this.filteredLanguages
        .filter(lang => lang.language_id === this.selectedLanguageId)
        .pop();
      return _.get(selectedLanguage, "language_provider") === "google";
    },
    dynamicFormSource: {
      get() {
        return (
          this.nodeToBind.open_form_node.data.property_rules.data.source || "{}"
        );
      },
      set({ expression }) {
        this.$set(
          this.nodeToBind.open_form_node.data.property_rules.data,
          "source",
          expression
        );
      }
    },
    /**
     * dynamic form check
     * @returns {boolean}
     */
    isDynamicForm() {
      return (
        this.nodeToBind.open_form_node &&
        this.nodeToBind.open_form_node.data.form_type_model === DYNAMIC_FORM
      );
    }
  },
  async created() {
    this.initializing = true;

    if (!this.nodeToBind.node_id || _.isEmpty(this.nodeToBind.open_form_node)) {
      this.initializeOpenFormNodeData();
    } else {
      if (
        this.nodeToBind.open_form_node.data.user_input_nodes_setting.data
          .no_input_canvas_id > 0
      ) {
        this.$set(
          this.nodeToBind.open_form_node.data.user_input_nodes_setting.data,
          "no_input_canvas_id",
          Object.assign({}, eventHandlerCanvasInitialize, {
            canvas_id: this.noInputEventHandlerCanvas
          })
        );
      }

      if (
        this.nodeToBind.open_form_node.data.user_input_nodes_setting.data
          .no_match_canvas_id > 0
      ) {
        this.$set(
          this.nodeToBind.open_form_node.data.user_input_nodes_setting.data,
          "no_match_canvas_id",
          Object.assign({}, eventHandlerCanvasInitialize, {
            canvas_id: this.noMatchEventHandlerCanvas
          })
        );
      }

      if (
        !_.has(this.nodeToBind.open_form_node.data, "user_input_nodes_setting")
      ) {
        this.$set(
          this.nodeToBind.open_form_node.data,
          "user_input_nodes_setting",
          {}
        );
        this.$set(
          this.nodeToBind.open_form_node.data.user_input_nodes_setting,
          "data",
          {
            ...SpeechInputConfirmation.default_value
          }
        );
        // Init default ASR model as empty
        this.$set(
          this.nodeToBind.open_form_node.data.user_input_nodes_setting.data,
          "asr_model",
          ""
        );
      }
    }

    if (_.isEmpty(this.complexVariables) && !_.isEmpty(this.clickedNode)) {
      await getComplexVariables(this.clickedNode.task_id)
        .then(({ data }) => {
          this.complexVariables = data.data;
        })
        .catch(err => {
          console.log(err);
        });
    }
    EventBus.$on("fetch-form-type", this.initializeOpenFormTypes);
  },

  async mounted() {
    await Promise.all([
      this.initializeOpenFormTypes(),
      this.initializeLanguages(),
      this.initializeDataStores()
    ]).then(() => {
      let results;
      let type;
      if (
        !this.isTaskReadOnly &&
        this.nodeToBind.open_form_node.data.form_type_id > 0
      ) {
        let form_type_id = this.nodeToBind.open_form_node.data.form_type_id;
        let form_type_model = this.nodeToBind.open_form_node.data
          .form_type_model;
        this.setReturnValues(form_type_id, form_type_model);

        let maskedFormTypeDataObj = this.getFormTypeObj(
          form_type_id,
          form_type_model
        );
        this.form_type_obj_temp = maskedFormTypeDataObj[0];
      }

      if (
        this.nodeToBind.open_form_node.data.form_type_id > 0 &&
        !_.isEmpty(this.nodeToBind.open_form_node.data.variable_rules.data)
      ) {
        //  remove variable name root from the data
        const assignedVariableRuleData = _.remove(
          this.nodeToBind.open_form_node.data.variable_rules.data,
          function(rule) {
            return rule.rule_value !== "root";
          }
        );

        this.nodeToBind.open_form_node.data.variable_rules.data = assignedVariableRuleData;
        this.setTranscribeTextObj();
        this.setConfidenceObj();
        this.setUserUtteranceAudioObj();
        this.removeInvalidVariableRules();

        // Compare and extract the return values that are not mapped to variables
        results = this.selectedReturnValues.filter(
          ({ output_id: id1 }) =>
            !assignedVariableRuleData.some(({ rule_value: id2 }) => {
              type = this.nodeToBind.open_form_node.data.form_type_model;
              let parseId2 =
                type === OPEN_FORM || type === DYNAMIC_FORM
                  ? parseInt(id2)
                  : id2;
              return parseId2 === id1;
            })
        );
      } else {
        results = this.selectedReturnValues;
      }

      // Initializing the return values not mapped to any variable
      let vm = this;
      _.forEach(results, function(value) {
        vm.nodeToBind.open_form_node.data.variable_rules.data.push({
          return_value_name: value.output_name,
          rule_value: value.output_id,
          variable_id: "",
          variable_name: "",
          default_value: "",
          msg: ""
        });
      });

      this.initializing = false;
    });

    this.initializePrompts();
    this.initializeVariableOptions();
    this.initializeDSBoostColumnValueSelect(
      this.nodeToBind.open_form_node.data.ds_boost_column
    );

    // // Make sure ASR model has value if older data is loaded without the ASR model property
    // AdvancedSpeechParameters.setAsrModelSelectorDefaultValue(
    //   this.nodeToBind.open_form_node.data.user_input_nodes_setting.data,
    //   this.filteredLanguages,
    //   this.selectedLanguageId,
    //   this.$set
    // );
  },
  methods: {
    generateDSBoostVariableOptions(variables) {
      return _.map(variables, variable => {
        return {
          column_id: "{{" + variable.variable_id + "}}",
          column_name: variable.variable_name,
          key: `var_${variable.variable_id}`
        };
      });
    },

    initializeVariablesList() {
      this.$data.$pendingVariablesList = _.cloneDeep(
        this.$data.$completeVariablesList
      );
      let removedItems = this.$data.$pendingVariablesList.splice(0, 10);

      removedItems = this.generateDSBoostVariableOptions(removedItems);
      this.$data.$variablesList = _.cloneDeep(removedItems);
    },

    // initializeBoostColumnListAfterClear() {
    //   this.$nextTick(() => {
    //     this.initializeVariablesList();
    //   });
    // },

    initializeVariableOptions() {
      this.$data.$completeVariablesList = _.cloneDeep(
        this.singleValuedAndSecureVariables
      );
      this.initializeVariablesList();
    },

    handleDSBoostColumnDropdownVisibleChange(isOptionsOpen) {
      this.$nextTick(() => {
        if (!isOptionsOpen) {
          this.$data.$pendingVariablesList = _.cloneDeep(
            this.$data.$completeVariablesList
          );
          let index = _.findIndex(
            this.$data.$pendingVariablesList,
            variable =>
              variable.variable_id.toString() ===
              (
                this.nodeToBind.open_form_node.data.ds_boost_column || ""
              ).replace(/^{{(\d+?)}}$/, "$1")
          );
          if (index > -1) {
            let item = this.$data.$pendingVariablesList.splice(index, 1);
            item = this.generateDSBoostVariableOptions(item);
            let removedItems = this.$data.$pendingVariablesList.splice(0, 10);
            removedItems = this.generateDSBoostVariableOptions(removedItems);
            this.$data.$variablesList = item.concat(_.cloneDeep(removedItems));
          } else {
            let removedItems = this.$data.$pendingVariablesList.splice(0, 10);
            removedItems = this.generateDSBoostVariableOptions(removedItems);
            this.$data.$variablesList = _.cloneDeep(removedItems);
          }
        }
      });
    },

    initializeDSBoostColumnValueSelect(value) {
      this.filterList("");
      this.$nextTick(() => {
        let index = _.findIndex(
          this.$data.$pendingVariablesList,
          variable =>
            variable.variable_id.toString() ===
            (value || "").replace(/^{{(\d+?)}}$/, "$1")
        );
        if (index > -1) {
          let item = this.$data.$pendingVariablesList.splice(index, 1);
          item = this.generateDSBoostVariableOptions(item);
          this.$data.$variablesList = item.concat(this.$data.$variablesList);
        }
      });
    },
    filterList($query) {
      this.dsBoostColumnQuery = $query;
      if (!$query) {
        this.$data.$pendingVariablesList = _.cloneDeep(
          this.$data.$completeVariablesList
        );
      } else {
        let perfectMatches = _.cloneDeep(
          _.filter(
            this.$data.$completeVariablesList,
            variable =>
              variable.variable_name.toLowerCase() === $query.toLowerCase()
          )
        );

        let perfectMatchNames = _.map(perfectMatches, variable =>
          variable.variable_name.toLowerCase()
        );

        this.$data.$pendingVariablesList = perfectMatches.concat(
          _.cloneDeep(
            _.filter(
              this.$data.$completeVariablesList,
              variable =>
                variable.variable_name
                  .toLowerCase()
                  .includes($query.toLowerCase()) &&
                !perfectMatchNames.includes(
                  variable.variable_name.toLowerCase()
                )
            )
          )
        );
      }
      let removedItems = this.$data.$pendingVariablesList.splice(0, 10);
      removedItems = this.generateDSBoostVariableOptions(removedItems);
      this.$data.$variablesList = removedItems;
    },

    load() {
      let removedItems = this.$data.$pendingVariablesList.splice(0, 10);
      removedItems = this.generateDSBoostVariableOptions(removedItems);
      this.$data.$variablesList = this.$data.$variablesList.concat(
        removedItems
      );
    },

    initializeOpenFormNodeData() {
      this.nodeToBind = Object.assign(
        {},
        this.nodeToBind,
        _.cloneDeep(initOpenFormNode)
      );
    },

    ...mapActions("openFormTypes", {
      getOpenFormTypes: "getOpenFormTypes",
      resetOpenFormTypes: "resetOpenFormTypes"
    }),
    ...mapActions("dynamicFormTypes", {
      getDynamicFormTypes: "getDynamicFormTypes"
    }),
    ...mapActions("formTypes", {
      getFormTypesWithDatastoreColumns: "getFormTypesWithDatastoreColumns",
      resetFormTypes: "resetFormTypes"
    }),
    ...mapActions("prompts", {
      getPrompts: "getPrompts"
    }),
    ...mapActions("asrLanguages", {
      getASRLanguages: "getASRLanguages"
    }),
    ...mapActions("dataStores", {
      getDataStores: "getDataStores",
      resetDataStores: "resetDataStores"
    }),
    async initializeOpenFormTypes() {
      this.resetOpenFormTypes();
      this.resetFormTypes();
      let apis = [
        this.getFormTypesWithDatastoreColumns({
          is_archived: "all",
          fetch_all: 1
        }),
        this.getDynamicFormTypes({ is_archived: "all", fetch_all: 1 })
      ];
      if (this.accountRawNodes && this.accountRawNodes[0]) {
        let selectedAcNodes = _.find(this.accountRawNodes, {
          ac_id: this.selectedAccountId
        });
        if (selectedAcNodes && selectedAcNodes.voice) {
          if (
            selectedAcNodes.voice &&
            selectedAcNodes.voice.includes("open_form")
          ) {
            apis.push(
              this.getOpenFormTypes({ is_archived: "all", fetch_all: 1 })
            );
          }
        }
      }
      await Promise.all(apis);
    },
    initializePrompts() {
      if (!this.prompts.length) {
        this.getPrompts();
      }
    },
    async initializeLanguages() {
      await this.getASRLanguages({ fetch_all: 0 });
      this.$nextTick(() => {
        this.checkASRLanguagesLength();
      });
    },

    async checkASRLanguagesLength() {
      if (!this.asrLanguages.length && !this.asrLanguagesLoading) {
        this.$message({
          type: "error",
          // eslint-disable-next-line
          message: __("ASR languages are not provisioned in your business plan")
        });
      }
    },

    async handleTimezoneChange(timezone) {
      this.nodeToBind.open_form_node.data.form_timezone = timezone;
    },
    async initializeDataStores() {
      await this.getDataStores({ fetch_all: 1, is_log: 0 });
    },
    onDataStoreChange() {
      this.nodeToBind.open_form_node.data.ds_phrase_hints_column = null;
      this.nodeToBind.open_form_node.data.ds_boost_column = null;
    },
    setTranscribeTextObj() {
      let transcribeTextVar = _.find(
        this.nodeToBind.open_form_node.data.variable_rules.data,
        ["rule_value", this.transcribeTextRuleValue]
      );
      if (!_.isEmpty(transcribeTextVar)) {
        this.removeVariableRule(this.transcribeTextRuleValue);
        this.transcribeTextObj = transcribeTextVar;
      }
    },

    setMaxNumberOfInputCount(val) {
      this.$set(
        this.nodeToBind.open_form_node.data.user_input_nodes_setting.data,
        "no_max_number_of_input_count",
        val
      );
    },

    setConfidenceObj() {
      let confidenceVar = _.find(
        this.nodeToBind.open_form_node.data.variable_rules.data,
        ["rule_value", this.confidenceRuleValue]
      );
      if (!_.isEmpty(confidenceVar)) {
        this.removeVariableRule(this.confidenceRuleValue);
        this.confidenceObj = confidenceVar;
      }
    },

    setUserUtteranceAudioObj() {
      let userUtteranceAudioVar = _.find(
        this.nodeToBind.open_form_node.data.variable_rules.data,
        ["rule_value", this.userUtteranceAudioRuleValue]
      );
      if (!_.isEmpty(userUtteranceAudioVar)) {
        this.removeVariableRule(this.userUtteranceAudioRuleValue);
        this.userUtteranceAudioObj = userUtteranceAudioVar;
      }
    },
    // removed archived formtype if it is not used/mapped in the node already
    removeArchivedFormTypes(formTypes) {
      let selectedFormTypeRefId = _.get(this.form_type_obj_temp, "id");
      return _.reject(formTypes, function(o) {
        return o.id === selectedFormTypeRefId ? false : o.is_archived;
      });
    },

    removeInvalidVariableRules() {
      //remove variable rules that don't have a valid openform or custom return value to map
      // this scenario occur when we remove the return value from open form type that is used inside open form node
      const self = this;
      _.remove(
        this.nodeToBind.open_form_node.data.variable_rules.data,
        function(rule) {
          return (
            rule.rule_value !== "root" &&
            _.isEmpty(rule.default_value) &&
            !_.startsWith(rule.rule_value, "sys.") &&
            !self.selectedReturnValues.some(({ output_id: id2 }) => {
              let type = self.nodeToBind.open_form_node.data.form_type_model;
              let parseId1 =
                type === OPEN_FORM || type === DYNAMIC_FORM
                  ? parseInt(rule.rule_value)
                  : rule.rule_value;
              return parseId1 === id2;
            })
          );
        }
      );
    },

    setDTMFGrammarLabel(columnName) {
      let dtmfLabel =
        _.get(this.form_type_obj_temp, "dtmf") === columnName ? " (DTMF)" : "";
      let grammarLabel =
        _.get(this.form_type_obj_temp, "grammar") === columnName
          ? " (Grammar)"
          : "";
      return columnName + dtmfLabel + grammarLabel;
    },
    updateBuiltInFormParameters(val) {
      if (this.checkNlpEngineType === "builtin") {
        this.$set(
          this.nodeToBind.open_form_node.data.user_input_nodes_setting.data,
          "form_parameters",
          val ? JSON.stringify(val) : "{}"
        );
      }
    },
    arrayRule() {
      let arrayRule = _.find(
        this.getVariableRules,
        rule => rule.variable_name === this.arrayVariableName
      );
      let { variable_name, variable_id } = this.arrayVariable;
      if (!arrayRule) {
        arrayRule = {
          rule_value: "root",
          default_value: "",
          variable_name,
          variable_id,
          array_variable: true,
          variable_type: "array"
        };
        this.nodeToBind.open_form_node.data.variable_rules.data.push(arrayRule);
      }
      return arrayRule;
    },
    maskCustomFormTypesData(customFormTypesData) {
      return customFormTypesData.map(v => {
        let option_group_label = v.is_standard ? __("Built-In") : __("Custom");
        return Object.assign(v, {
          oft_name: v.form_type_name,
          nlp_engine: option_group_label,
          type: CUSTOM_FORM,
          id: _.toString(v.open_form_type_id).concat("_openform")
        });
      });
    },

    /**
     * mask the dynamic form types
     * @param dynamicFormTypesData
     * @returns {*}
     */
    maskDynamicFormTypesData(dynamicFormTypesData) {
      return dynamicFormTypesData.map(v => ({
        ...v,
        type: DYNAMIC_FORM,
        nlp_engine: "custom",
        id: _.toString(v.dynamic_form_type_id).concat("_dynamicform")
      }));
    },
    // return Object
    maskOpenFormTypesData(openFormTypesData) {
      return openFormTypesData.map(v => ({
        ...v,
        type: OPEN_FORM,
        id: _.toString(v.open_form_type_id).concat("_openform")
      }));
    },

    /**
     * return Array - the masked FormTypeObject
     * @param form_type_id
     * @param form_type_model
     * @returns {*}
     */
    getFormTypeObj(form_type_id, form_type_model) {
      switch (form_type_model) {
        case OPEN_FORM: {
          const openFormType = _.find(this.openFormTypes, {
            open_form_type_id: form_type_id
          });

          return this.maskOpenFormTypesData([openFormType]);
        }

        case CUSTOM_FORM: {
          const customFormType = _.find(this.formTypes, {
            form_type_id: form_type_id
          });

          return this.maskCustomFormTypesData([customFormType]);
        }

        case DYNAMIC_FORM: {
          const dynamicFormType = _.find(this.dynamicFormTypes, {
            dynamic_form_type_id: form_type_id
          });
          return this.maskDynamicFormTypesData([dynamicFormType]);
        }
      }
    },
    updateText(key, value) {
      this.$set(
        this.nodeToBind.open_form_node.data.user_input_nodes_setting.data,
        key,
        value
      );
    },
    updatePhraseHints(value) {
      this.$set(this.nodeToBind.open_form_node.data, "phrase_hints", value);
    },
    updateFormParameters(value) {
      this.$set(
        this.nodeToBind.open_form_node.data.user_input_nodes_setting.data,
        "form_parameters",
        value
      );
    },
    updateNoMatchPromptText(value) {
      this.$set(
        this.nodeToBind.open_form_node.data.user_input_nodes_setting.data,
        "no_match_prompt_text",
        value
      );
    },

    // System default return values are raw transcibe text, confidence score, user utterance audio
    removeAllSystemDefaultReturnValues() {
      _.remove(this.nodeToBind.open_form_node.data.variable_rules.data, obj =>
        _.startsWith(obj.rule_value, "sys.")
      );
    },

    removeVariableRule(rule_value) {
      _.remove(
        this.nodeToBind.open_form_node.data.variable_rules.data,
        obj => obj.rule_value === rule_value
      );
    },
    handleChangeRecordFileToAudioVariable(option) {
      this.newVariableCreated = option.value === -1;
      this.newVariableForAudioVar = _.isEmpty(option.msg) ? 0 : 1;
      this.userUtteranceAudioObj = {
        variable_id: option.value,
        variable_name: option.label,
        variable_type: "audio",
        msg: option.msg,
        rule_value: this.userUtteranceAudioRuleValue
      };
    },
    handleChangeTranscribedTextToVariable(option) {
      this.newVariableCreated = option.value === -1;
      this.newVariableForTranscribedTextVar = _.isEmpty(option.msg) ? 0 : 1;
      this.transcribeTextObj = {
        variable_id: option.value,
        variable_name: option.label,
        variable_type: "single_value",
        msg: option.msg,
        rule_value: this.transcribeTextRuleValue,
        type: "system"
      };
    },
    updateAsrModel(val) {
      this.$set(
        this.nodeToBind.open_form_node.data.user_input_nodes_setting.data,
        "asr_model",
        val
      );
    },

    handleChangeConfidenceToVariable(option) {
      this.newVariableCreated = option.value === -1;
      this.newVariableForConfidenceVar = _.isEmpty(option.msg) ? 0 : 1;
      this.confidenceObj = {
        variable_id: option.value,
        variable_name: option.label,
        variable_type: "single_value",
        msg: option.msg,
        rule_value: this.confidenceRuleValue
      };
    },

    // handle the change the of Return values table rows
    handleChange(option) {
      return row => {
        this.$nextTick(() => {
          if (
            !_.isEmpty(this.selectedReturnValues) &&
            row.variable_type !== "array"
          ) {
            let type = this.nodeToBind.open_form_node.data.form_type_model;
            let returnValueName;
            // Filter the Openform's oft_outputs collection to find the output name with Id
            if (type === OPEN_FORM) {
              let outputIdInt = parseInt(row.rule_value);
              let mapReturnValName = _.find(this.selectedReturnValues, [
                "output_id",
                outputIdInt
              ]);
              returnValueName = mapReturnValName.output_name;
            } else if (type === DYNAMIC_FORM) {
              let outputIdInt = parseInt(row.rule_value);
              let mapReturnValName = _.find(this.selectedReturnValues, [
                "output_id",
                outputIdInt
              ]);

              returnValueName = mapReturnValName.output_name;
            } else {
              returnValueName = row.rule_value;
              // Set DTMF and Grammar Label Only for Custom Type
              if (this.checkNlpEngineType === "custom") {
                returnValueName = this.setDTMFGrammarLabel(row.rule_value);
              }
            }

            this.$set(row, "return_value_name", returnValueName);
            this.$set(row, "variable_name", option.label);
            this.$set(row, "variable_id", option.value);
            this.$set(row, "msg", option.msg);
          }
        });
      };
    },
    //  Set return values to selectedReturnValues
    setReturnValues(form_type_id, type) {
      if (form_type_id > 0 && !_.isEmpty(type)) {
        let formTypesCloned;
        let returnVal = [];

        if (type === CUSTOM_FORM) {
          if (
            this.selectedCustomFormType &&
            !_.isEmpty(this.selectedCustomFormType.collection_structure)
          ) {
            returnVal = this.selectedCustomFormType.collection_structure;
            returnVal = returnVal.map(v => {
              return Object.assign(v, {
                output_id: v.col_name,
                output_name: v.col_name
              });
            });
          }
        } else if (type === DYNAMIC_FORM) {
          formTypesCloned = _.cloneDeep(this.dynamicFormTypes);
          let filterDynamicFormTypes = _.filter(formTypesCloned, {
            dynamic_form_type_id: form_type_id
          });

          returnVal = filterDynamicFormTypes[0].dft_outputs.filter(
            result => result.output_type === "RETURN_COLUMN"
          );
        } else {
          returnVal =
            this.selectedOpenFormType && this.selectedOpenFormType.oft_outputs;
        }

        this.selectedReturnValues = returnVal instanceof Array ? returnVal : [];
      }
    },

    //  Triggered after the formtype drop-down changes
    formTypeIdSelection(row) {
      this.intializing = true;
      let selectedFormTypeId;
      if (row.type === CUSTOM_FORM) {
        selectedFormTypeId = row.form_type_id;
        this.resetFormParameters();

        this.$set(this.nodeToBind.open_form_node.data, "provider", "lumenvox");
      } else {
        if (row.type === OPEN_FORM || row.type === DYNAMIC_FORM) {
          // Reset confirmation data back to default value
          Object.keys(SpeechInputConfirmation.default_value)
            .filter(key => key.startsWith("confirmation_"))
            .forEach(key => {
              this.$set(
                this.nodeToBind.open_form_node.data.user_input_nodes_setting
                  .data,
                key,
                SpeechInputConfirmation.default_value[key]
              );
            });
        }

        selectedFormTypeId =
          row.type === OPEN_FORM
            ? row.open_form_type_id
            : row.dynamic_form_type_id;
        if (row.type === DYNAMIC_FORM) {
          this.$set(
            this.nodeToBind.open_form_node.data,
            "provider",
            "lumenvox"
          );
        } else {
          this.$set(this.nodeToBind.open_form_node.data, "provider", "");
        }
        this.$set(
          this.nodeToBind.open_form_node.data.user_input_nodes_setting.data,
          "form_parameters",
          ""
        );
        this.$set(
          this.nodeToBind.open_form_node.data.user_input_nodes_setting.data,
          "confidence_level",
          0.0
        );
      }
      this.nodeToBind.open_form_node.data.form_type_id = selectedFormTypeId;
      this.nodeToBind.open_form_node.data.form_type_model = row.type;

      this.nodeToBind.open_form_node.data.user_input_nodes_setting.data.override_form_nlp_params = false;
      this.nodeToBind.open_form_node.data.user_input_nodes_setting.data.speech_complete_timeout =
        row.speech_complete_timeout;
      this.nodeToBind.open_form_node.data.user_input_nodes_setting.data.speech_incomplete_timeout =
        row.speech_incomplete_timeout;
      this.nodeToBind.open_form_node.data.user_input_nodes_setting.data.no_input_timeout =
        row.no_input_timeout;
      this.nodeToBind.open_form_node.data.user_input_nodes_setting.data.speech_start_timeout =
        row.speech_start_timeout;
      this.nodeToBind.open_form_node.data.user_input_nodes_setting.data.inter_result_timeout =
        row.inter_result_timeout;
      this.nodeToBind.open_form_node.data.user_input_nodes_setting.data.barge_in_sensitivity =
        row.barge_in_sensitivity;
      this.nodeToBind.open_form_node.data.user_input_nodes_setting.data.auto_punctuation =
        row.auto_punctuation;
      this.nodeToBind.open_form_node.data.user_input_nodes_setting.data.profanity_filter =
        row.profanity_filter;
      this.nodeToBind.open_form_node.data.user_input_nodes_setting.data.single_utterance =
        row.single_utterance;

      this.setReturnValues(selectedFormTypeId, row.type);
      this.$set(this.nodeToBind.open_form_node.data, "variable_rules", {});
      this.$set(this.nodeToBind.open_form_node.data.variable_rules, "data", []);
      this.$set(this.nodeToBind.open_form_node.data, "property_rules", {});
      this.$set(this.nodeToBind.open_form_node.data.property_rules, "data", {});
      let vm = this;
      let rules = [];
      _.forEach(this.selectedReturnValues, function(value) {
        rules.push({
          return_value_name: value.output_name,
          rule_value: value.output_id,
          variable_id: "",
          variable_name: "",
          default_value: "",
          msg: ""
        });
      });

      vm.nodeToBind.open_form_node.data.variable_rules.data = _.concat(
        vm.nodeToBind.open_form_node.data.variable_rules.data,
        rules
      );
      this.initializing = false;
    },
    setOpenformNodeJsonResponse() {
      let rule = this.arrayRule();
      rule.default_value = JSON.stringify(this.openFormNodeResponse);
    },

    setNoInputEventHandlerCanvas(option) {
      this.$set(
        this.nodeToBind.open_form_node.data.user_input_nodes_setting.data,
        "no_input_canvas_id",
        option
      );
      this.$refs.nodeForm.validateField(
        "open_form_node.data.user_input_nodes_setting.data.no_input_canvas_id"
      );
    },
    setNoMatchEventHandlerCanvas(option) {
      this.$set(
        this.nodeToBind.open_form_node.data.user_input_nodes_setting.data,
        "no_match_canvas_id",
        option
      );
      this.$refs.nodeForm.validateField(
        "open_form_node.data.user_input_nodes_setting.data.no_match_canvas_id"
      );
    },

    setNoMatchEventHandlerCount(val) {
      this.$set(
        this.nodeToBind.open_form_node.data.user_input_nodes_setting.data,
        "no_match_count",
        val
      );
    },
    setNoInputEventHandlerCount(val) {
      this.$set(
        this.nodeToBind.open_form_node.data.user_input_nodes_setting.data,
        "no_input_count",
        val
      );
    },

    handleChangeProvider() {
      this.resetLanguage();
      //re-render Advanced ASR setting component
      this.advanced_asr_rerender_key++;
    },

    resetLanguage() {
      this.selectedLanguageId = null;
    },
    resetFormParameters() {
      this.nodeToBind.open_form_node.data.user_input_nodes_setting.data.form_parameters = null;
    },

    setDefaultSystemVariableRules() {
      if (!_.isEmpty(this.transcribeTextObj)) {
        //remove the variable rule of transcribed text before pushing the latest value
        this.removeVariableRule(this.transcribeTextRuleValue);
        this.nodeToBind.open_form_node.data.variable_rules.data.push(
          this.transcribeTextObj
        );
      }

      if (!_.isEmpty(this.confidenceObj)) {
        //remove the variable rule of confidence before pushing the latest value
        this.removeVariableRule(this.confidenceRuleValue);
        this.nodeToBind.open_form_node.data.variable_rules.data.push(
          this.confidenceObj
        );
      }

      if (!_.isEmpty(this.userUtteranceAudioObj)) {
        //remove the variable rule of user utterance before pushing the latest value
        this.removeVariableRule(this.userUtteranceAudioRuleValue);
        this.nodeToBind.open_form_node.data.variable_rules.data.push(
          this.userUtteranceAudioObj
        );
      }
    },

    cleanUpFormTypeData(formType) {
      if (this.checkNlpEngineType === "builtin") {
        this.$set(this.nodeToBind.open_form_node.data, "provider", "lumenvox");
        this.removeAllSystemDefaultReturnValues();
      } else if (
        (formType === CUSTOM_FORM || formType === DYNAMIC_FORM) &&
        this.checkNlpEngineType !== "builtin"
      ) {
        this.$set(
          this.nodeToBind.open_form_node.data.user_input_nodes_setting.data,
          "form_parameters",
          ""
        );
      } else if (formType === OPEN_FORM) {
        this.$set(this.nodeToBind.open_form_node.data, "provider", "google");
        this.$set(
          this.nodeToBind.open_form_node.data.user_input_nodes_setting.data,
          "interdigit_timeout",
          0
        );
        if (this.checkNlpEngineType === "dialogflow") {
          this.$set(
            this.nodeToBind.open_form_node.data.user_input_nodes_setting.data,
            "form_timezone",
            ""
          );
        } else if (this.checkNlpEngineType === "lex") {
          this.$set(
            this.nodeToBind.open_form_node.data.user_input_nodes_setting.data,
            "form_parameters",
            ""
          );
        }
      }
    },
    getReturnValueName(rule, type) {
      // Only allow to return if it is not a system default return value
      // performance.mark("getReturnValueName");
      let retVal = "";
      if (
        !_.startsWith(rule.rule_value, "sys.") &&
        rule.variable_type !== "array"
      ) {
        if (type === CUSTOM_FORM) {
          // return column name with DTMF and Grammar Label
          retVal = this.setDTMFGrammarLabel(rule.return_value_name);
        } else {
          // map rule value with return value output_id from oft_outputs table
          let outputIdInt = parseInt(rule.rule_value);
          let mapReturnValName = _.find(this.selectedReturnValues, [
            "output_id",
            outputIdInt
          ]);
          retVal = mapReturnValName.output_name;
        }
      }
      // performance.measure("getReturnValue", "getReturnValueName");
      // performance.clearMarks("getReturnValueName");
      // performance.clearMeasures("getReturnValue");
      return retVal;
    },

    cleanUpNodeToPrepareForSubmit() {
      // do necessary operations on a cloned version of nodeToBind obj
      // that return the nodeToBind object
      // in exactly the same way as an node object of this
      // type is returned from the backend

      // as I do not require any cleanup to do here in this particular case,
      // I am just sending back a cloned version of nodeToBind obj

      if (
        this.nodeToBind.open_form_node &&
        this.nodeToBind.open_form_node.data.phrase_hints_ds_id === ""
      ) {
        this.nodeToBind.open_form_node.data.phrase_hints_ds_id = null;
        this.nodeToBind.open_form_node.data.ds_phrase_hints_column = null;
        this.nodeToBind.open_form_node.data.ds_boost_column = null;
      }
      return _.cloneDeep(this.nodeToBind);
    },

    cleanUpNode() {
      // the manipulation to clean up the node
      // is moved to the above method. So we can
      // reassign the nodeToBind object as the object
      // that is returned from the cleanUpNodeToPrepareForSubmit method
      this.setDefaultSystemVariableRules();
      this.variableRulesBkup = _.cloneDeep(
        this.nodeToBind.open_form_node.data.variable_rules.data
      );
      this.setDefaultSystemVariableRules();
      this.cleanUpFormTypeData(
        this.nodeToBind.open_form_node.data.form_type_model
      );

      let filteredVariableRulesData = filterRowsIfSomeKeyValueIsAbsent(
        this.nodeToBind.open_form_node.data.variable_rules.data,
        "variable_name"
      );

      this.$set(this.nodeToBind.open_form_node.data.variable_rules, "data", []);
      this.nodeToBind.open_form_node.data.variable_rules.data = filteredVariableRulesData;
      // setting node's json schema response to root variable
      this.setOpenformNodeJsonResponse();

      this.nodeToBind = this.cleanUpNodeToPrepareForSubmit();
      this.createOrEditNode();
    },
    createOrEditNode() {
      if (!this.isTaskReadOnly) {
        this.attemptedToSubmit = true;
        this.$refs.nodeForm.validate((valid, errors) => {
          if (valid) {
            this.process({
              node: this.nodeToBind,
              nodeInContext: this.node
            })
              .then(async () => {
                this.newVariableCreated
                  ? await this.forceFetchVariables()
                  : null;
                this.setClickedNode(null);
                this.toggleNodeSubmit(false);
              })
              .catch(() => {
                // this.newVariableCreated = false;
                this.toggleNodeSubmit(false);
              });
          } else {
            let errMessages = _.flatten(
              _.map(errors, err => {
                return _.map(err, "message");
              })
            );

            _.map(errMessages, message => {
              setTimeout(() => this.errorNotification(message), 100);
            });
            this.toggleNodeSubmit(false);
            this.nodeToBind.open_form_node.data.variable_rules.data = _.cloneDeep(
              this.variableRulesBkup
            );
            // this.newVariableCreated = false;
            let errorPropName = Object.keys(errors);
            let findTab = _.findKey(this.tabStructure, function(structure) {
              return structure.some(r => errorPropName.includes(r));
            });

            if (!_.isEmpty(findTab)) {
              this.activeTab = findTab;
            }
            return false;
          }
        });
      }
    },
    updateNlpParameters(nlp_param, value) {
      // console.log(nlp_param, value);
      this.$set(
        this.nodeToBind.open_form_node.data.user_input_nodes_setting.data,
        nlp_param,
        value
      );

      if (value !== false && value !== 0 && !value) {
        // console.log("using default");
        this.nodeToBind.open_form_node.data.user_input_nodes_setting.data[
          nlp_param
        ] = this.form_type_obj_temp[nlp_param];
      }

      //set the flag to false when all Node values are same as Form Type
      this.nodeToBind.open_form_node.data.user_input_nodes_setting.data.override_form_nlp_params = !(
        this.nodeToBind.open_form_node.data.user_input_nodes_setting.data
          .speech_complete_timeout ===
          this.form_type_obj_temp.speech_complete_timeout &&
        this.nodeToBind.open_form_node.data.user_input_nodes_setting.data
          .speech_incomplete_timeout ===
          this.form_type_obj_temp.speech_incomplete_timeout &&
        this.nodeToBind.open_form_node.data.user_input_nodes_setting.data
          .no_input_timeout === this.form_type_obj_temp.no_input_timeout &&
        this.nodeToBind.open_form_node.data.user_input_nodes_setting.data
          .speech_start_timeout ===
          this.form_type_obj_temp.speech_start_timeout &&
        this.nodeToBind.open_form_node.data.user_input_nodes_setting.data
          .inter_result_timeout ===
          this.form_type_obj_temp.inter_result_timeout &&
        this.nodeToBind.open_form_node.data.user_input_nodes_setting.data
          .barge_in_sensitivity ===
          this.form_type_obj_temp.barge_in_sensitivity &&
        this.nodeToBind.open_form_node.data.user_input_nodes_setting.data
          .auto_punctuation === this.form_type_obj_temp.auto_punctuation &&
        this.nodeToBind.open_form_node.data.user_input_nodes_setting.data
          .profanity_filter === this.form_type_obj_temp.profanity_filter &&
        this.nodeToBind.open_form_node.data.user_input_nodes_setting.data
          .single_utterance === this.form_type_obj_temp.single_utterance
      );
    },
    handleLanguageVarChanged(langVarId) {
      this.languageVariable = langVarId;
    },

    /**
     * cancel expression builder operation
     */
    handleCancelSourceExpressionBuilderModal() {
      this.displaySourceExpressionBuilderModal = false;
    }
  },
  // Watch gets triggered before computed property
  // Proper check must be in place to ensure that computed data is available before any process
  watch: {
    "nodeToBind.open_form_node.data.phrase_hints_ds_id": {
      deep: true,
      handler() {
        this.initializeVariableOptions();
      }
    },
    // This watcher set the newVariableCreated to true whenever new variable created
    variableRulesBkup: {
      deep: true,
      handler(val) {
        this.newVariableCreated = _.some(
          val,
          variable => variable.variable_id === -1
        );
      }
    },
    selectedReturnValues: {
      deep: true,
      handler() {
        let variableRules = _.cloneDeep(
          this.nodeToBind.open_form_node.data.variable_rules.data
        );
        let type = this.nodeToBind.open_form_node.data.form_type_model;

        this.nodeToBind.open_form_node.data.variable_rules.data = _.map(
          variableRules,
          rule => {
            return {
              ...rule,
              return_value_name: this.getReturnValueName(rule, type)
            };
          }
        );
      }
    },

    form_type_obj_temp: {
      deep: true,
      handler() {
        this.$nextTick(() => {
          if (
            this.selectedLanguageId > 0 &&
            !_.isEmpty(this.filteredLanguages)
          ) {
            let acceptableLanguage = _.find(this.filteredLanguages, [
              "language_id",
              this.selectedLanguageId
            ]);
            if (_.isEmpty(acceptableLanguage)) {
              this.selectedLanguageId = null;
            }
          }
        });
      }
    },

    /**
     * Selected language watcher. Reset the selected ASR model with the languages default
     * Return empty for now, we will change the default Asr model once we have data to suggest which is the most common one
     */
    "nodeToBind.open_form_node.data.form_language_id": function() {
      this.$nextTick(() => {
        // let val = getDefaultAsrModelFromLanguage(
        //   this.filteredLanguages,
        //   newValue
        // );
        this.$set(
          this.nodeToBind.open_form_node.data.user_input_nodes_setting.data,
          "asr_model",
          ""
        );
      });
    },

    currentProvider: {
      immediate: true,
      handler(val) {
        if (this.checkNlpEngineType === "lex") {
          this.preText = "Amazon Lex";
        } else if (this.checkNlpEngineType === "dialogflow") {
          this.preText = "Google dialogflow";
        } else if (val === "lumenvox" && this.isValidLumenvoxEngine) {
          this.preText = "Lumenvox";
        } else {
          this.preText = "Google Cloud Speech to Text";
        }
      }
    },

    checkNlpEngineType(val) {
      if (val === "lex") {
        this.preText = "Amazon Lex";
      } else if (val === "dialogflow") {
        this.preText = "Google dialogflow";
      } else if (
        this.currentProvider === "lumenvox" &&
        this.isValidLumenvoxEngine
      ) {
        this.preText = "Lumenvox";
      } else {
        this.preText = "Google Cloud Speech to Text";
      }
    },

    asrLanguages: {
      immediate: true,
      deep: true,
      handler(val) {
        this.preTextToLanguagesMap["Amazon Lex"] = _.filter(val, [
          "language_provider",
          "amazon"
        ]);

        this.preTextToLanguagesMap["Google dialogflow"] = _.filter(
          val,
          function(o) {
            return (
              o.language_provider === "google" &&
              o.language_used_in.includes("dialogflow")
            );
          }
        );

        this.preTextToLanguagesMap["Lumenvox"] = _.filter(val, [
          "language_provider",
          "lumenvox"
        ]);

        this.preTextToLanguagesMap["Google Cloud Speech to Text"] = _.filter(
          val,
          function(o) {
            return (
              o.language_provider === "google" &&
              o.language_used_in.includes("cloud_speech")
            );
          }
        );
      }
    }
  },

  beforeDestroy() {
    EventBus.$off("fetch-form-type");
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/element-variables.scss";
@import "~@/styles/node_common.scss";

.hide {
  display: none;
}

::v-deep .row-message {
  font-size: 0.75rem;
  color: $--color-success;
  margin-left: 10px;
}

.audio-player {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.promptEditor {
  ::v-deep .editableContent {
    height: 200px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border: 1px solid #a0a8b5;
    outline: none;
    padding: 10px;
    overflow: auto;
    /*&:focus {*/
    /*  border: 1px solid black;*/
    /*}*/
  }
}

.phraseHintEditor {
  ::v-deep .editableContent {
    height: 200px;
    border-radius: 6px;
    border: 1px solid #a0a8b5;
    /*<!--box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2), 0 3px 4px 0 rgba(0, 0, 0, 0.14),-->*/
    /*<!--  0 3px 3px -2px rgba(0, 0, 0, 0.12);-->*/
    margin-left: 5px;
    outline: none;
    padding: 10px;
    overflow: auto;
  }
}
</style>
