<template>
  <div>
    <el-form-item :label="__('Note')" v-if="showNote">
      <span class="note" v-html="getNoteText"></span>
    </el-form-item>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  components: {
    // InputVariablePopper
  },
  name: "NoteComponent",
  props: {
    formType: {
      required: true,
      type: String
    },
    text: {
      required: false,
      type: String
    }
  },
  data() {
    return {
      noteText: {
        /* eslint-disable */
        "Yes No": [
          `<li>${__("The value for")} Yes ${__("is")} <code >yes</code>, ${__("the value for")} No ${__("is")} <code>no</code></li>`
        ],
        "Payment - Credit Card Number": [
          `<li><code>Credit Card Number </code><i class="el-icon-bank-card"></i> ${__("showing first 6 digits and last 4 digits in the format")} 123456xxxx1234</li>
           <li><code>Credit Card Number Check</code> ${__("is based on Luhn Algorithm. Will return either")} <code>valid or invalid</code></li>
           <li><code>Credit Card Type</code> ${__("will return")} <code>mastercard, visa, amex, discover, diners or jcb</code></li>
           <li>${__("The text to speech value of")} <strong>Credit Card Number</strong> ${__("is assigned to Payment Variable")} <code>card_number</code></li>
           <li>${__("The text to speech value of")} <strong>last 4 digits of Credit Card Number</strong> ${__("is assigned to Payment Variable")} <code>last_4_digits_of_card_number</code></li>`
        ],
        "Payment - Credit Card Expiry": [
          `<li>${__("The text to speech value of")} <strong>Credit Card Expiry</strong> ${__("is assigned to Payment Variable")} <code>card_expiry</code>, ${__("format")}: mmyy, ${__("e.g.")}. 0517</li>`
        ],
        "Payment - Credit Card CVC": [
          `<li>${__("The text to speech value of")} <strong>Credit Card CVC</strong> ${__("is assigned to Payment Variable")} <code>card_cvc</code></li>`
        ]
        /* eslint-enable */
      }
    };
  },
  computed: {
    getNoteText: function() {
      let findNote = _.get(this.noteText, this.formType, []);
      return findNote.toString();
    },
    showNote: function() {
      return !_.isEmpty(this.getNoteText);
    }
  },
  mounted() {},
  methods: {}
};
</script>

<style lang="scss" scoped>
.note {
  font-size: 14px;
  color: #3e4c59;
}
::v-deep code {
  color: #e56a54;
  font-weight: bold;
}
</style>
