<template>
  <div>
    <el-form-item :label="__('Specify Length')">
      <el-row type="flex">
        <el-col :span="6">{{ minMaxValues.start.label }}</el-col>
        <el-col :span="6" :offset="2">{{ minMaxValues.end.label }}</el-col>
      </el-row>
      <el-row type="flex">
        <el-col :span="6">
          <!--      <h4>Minimum No. of Digits</h4>-->
          <el-input-number
            size="small"
            :value="defaultFormTypeParams.start"
            :min="minMaxValues.start.min"
            :step="1"
            :max="minMaxValues.start.max"
            :step-strictly="true"
            @change="updateValue($event, 'start')"
          ></el-input-number>
        </el-col>
        <!--      <h4>Maximum No. of Digits</h4>-->
        <el-col :span="6" :offset="2">
          <el-input-number
            size="small"
            :value="defaultFormTypeParams.end"
            :min="minMaxValues.end.min"
            :step="1"
            :max="minMaxValues.end.max"
            :step-strictly="true"
            @change="updateValue($event, 'end')"
          ></el-input-number>
        </el-col>
      </el-row>
    </el-form-item>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  name: "DefaultFormTypes",
  props: {
    formType: {
      required: true,
      type: String,
      default: ""
    },
    value: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      defaultRange: { start: 0, end: 0 },
      custom_digit_string: {
        start: { label: __("Minimum no. of digits"), min: 1, max: 20 },
        end: { label: __("Maximum no. of digits"), min: 1, max: 20 }
      },
      scale: {
        start: { label: __("Start of scale"), min: 0, max: 1 },
        end: { label: __("End of scale"), min: 2, max: 100 }
      },
      custom_alpha_numeric_string: {
        start: { label: __("Minimum no. of characters"), min: 1, max: 20 },
        end: { label: __("Maximum no. of characters"), min: 1, max: 20 }
      }
    };
  },
  computed: {
    minMaxValues() {
      switch (this.formType) {
        case "Custom Digit String":
          return this.custom_digit_string;
        case "Scale":
          return this.scale;
        case "Custom Alpha Numeric String":
          return this.custom_alpha_numeric_string;
        default:
          return {};
      }
    },
    defaultFormTypeParams() {
      if (_.isEmpty(this.value)) {
        return this.defaultRange;
      }
      return this.value;
    }
  },
  methods: {
    updateValue(val, key) {
      let valueObj = this.defaultFormTypeParams;
      this.$set(valueObj, key, val);
      this.$emit("update-value", valueObj);
    }
  },
  mounted() {
    if (_.isEmpty(this.value)) {
      switch (this.formType) {
        case "Custom Digit String":
          this.$set(
            this.defaultRange,
            "start",
            this.custom_digit_string.start.min
          );
          this.$set(this.defaultRange, "end", this.custom_digit_string.end.min);
          break;
        case "Scale":
          this.$set(this.defaultRange, "start", this.scale.start.min);
          this.$set(this.defaultRange, "end", this.scale.end.min);
          break;
        case "Custom Alpha Numeric String":
          this.$set(
            this.defaultRange,
            "start",
            this.custom_alpha_numeric_string.start.min
          );
          this.$set(
            this.defaultRange,
            "end",
            this.custom_alpha_numeric_string.end.min
          );
          break;
      }
    }

    this.$emit("update-value", this.defaultFormTypeParams);
  },
  watch: {
    formType: function() {
      this.$emit("update-value", this.defaultFormTypeParams);
    }
  }
};
</script>

<style scoped></style>
