<template>
  <el-row type="flex">
    <el-col :span="16">
      <div class="phraseHintEditor">
        <!--              <input-variable-popper-->
        <!--                :value="ruleBckup"-->
        <!--                is-content-editable-->
        <!--                :is-text-area="false"-->
        <!--                :ats="['{{']"-->
        <!--                popper-class="prompt-node-popper"-->
        <!--                @input="updateSpecifyRules($event)"-->
        <!--              />-->
        <!--              <el-input type="textarea" :rows="6" :value="ruleBckup">-->
        <el-tag
          :key="index"
          v-for="(tag, index) in dynamicTags"
          closable
          :disable-transitions="false"
          @close="handleClose(tag)"
        >
          {{ tag }}
        </el-tag>
        <el-input
          class="input-new-tag"
          v-if="inputVisible"
          v-model="inputValue"
          ref="saveTagInput"
          size="mini"
          maxlength="40"
          show-word-limit
          @keyup.enter.native="handleInputConfirm"
          @blur="handleInputConfirm"
        >
        </el-input>
        <el-button v-else class="button-new-tag" size="small" @click="showInput"
          >+ {{ __("Add Rule") }}</el-button
        >
        <!--              </el-input>-->
      </div>
    </el-col>
    <el-col :span="11" :offset="1">
      <el-input
        v-model="testText"
        :placeholder="__('Enter value to test the rule')"
      >
        <el-button
          type="primary"
          slot="append"
          :loading="requestingHttpResponse"
          @click="evaluateAlphaNumeric"
          >{{ __("Test") }}</el-button
        >
      </el-input>

      <!--            <el-row class="json-path-result-row" type="flex" justify="start">-->
      <!--              <el-col-->
      <!--                class="json-path-result-container"-->
      <!--              >-->
      <span v-if="evaluatedStatus" v-html="evaluatedStatus"> </span>
      <span
        v-if="evaluatedResult"
        class="json-path-result"
        v-html="evaluatedResult"
      >
      </span>
      <!--            <strong> For Example:</strong><br />-->
      <!--            <span>-->
      <!--              1. Any Digit: 0-9<br />-->
      <!--              2. Any letter: a-z<br />-->
      <!--              3. Digits from 1 to 3: 1-3 or 1,2,3<br />-->
      <!--            </span>-->
    </el-col>
  </el-row>
  <!--          </el-col>-->
  <!--        </el-row>-->
</template>

<script>
// import { mapActions, mapState } from "vuex";
// import _ from "lodash";

import _ from "lodash";
import { evaluateAlphaNumeric } from "@/api/services";

// import InputVariablePopper from "@/views/build/callflow/components/node-type-forms/components/InputVariablePopper";

export default {
  components: {
    // InputVariablePopper
  },
  name: "AlphaNumeric",
  props: {
    formType: {
      required: true,
      type: String
    },
    value: {
      required: true,
      type: Object
    },
    returnValues: {
      required: false,
      type: Array
    }
  },
  data() {
    return {
      ruleBckup: "",
      testText: "",

      dynamicTags: [],
      inputVisible: false,
      inputValue: "",
      httpResponse: [],
      requestingHttpResponse: false,
      evaluatedResult: "",
      evaluatedStatus: "",
      popOverContent:
        `<span>` +
        __("1. Any Digit:") +
        `<span style="color: var(--theme-color);">` +
        "0-9" +
        `</span></span><br/><span>` +
        __("2. Any letter:") +
        `<span style="color: var(--theme-color);">` +
        "a-z" +
        `</span></span><br /><span>` +
        __("3. Digits from 1 to 3:") +
        `<span style="color: var(--theme-color);">` +
        "1-3" +
        __("or") +
        "1,2,3" +
        `</span></span><br /><span>` +
        __("4. Characters a or b or f:") +
        `<span style="color: var(--theme-color);">` +
        "a,b,f" +
        `</span></span><br /><span>` +
        __("5. Character c or any digit:") +
        `<span style="color: var(--theme-color);">` +
        "c, 0-9" +
        `</span></span><br />`
    };
  },
  computed: {},
  mounted() {
    if (_.isEmpty(this.value)) {
      this.$set(this.value, "rules", []);
    }
    this.setRulesValue();
  },
  methods: {
    setRulesValue() {
      if (!_.isEmpty(this.value.rules)) {
        this.dynamicTags = this.value.rules;
      }
    },
    updateSpecifyRules(rules) {
      this.$set(this.value, "rules", rules);
      this.$emit("input", this.value);
    },
    handleClose(tag) {
      this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
      this.updateSpecifyRules(this.dynamicTags);
    },

    showInput() {
      this.inputVisible = true;
      this.$nextTick(() => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },

    handleInputConfirm() {
      let inputValue = this.inputValue;
      if (inputValue) {
        this.dynamicTags.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = "";
      this.updateSpecifyRules(this.dynamicTags);
    },
    truncate(value, limit = 15) {
      if (value && value.length <= limit) {
        return value;
      } else if (value) {
        return value.substring(0, limit) + "...";
      } else {
        return value;
      }
    },
    evaluateAlphaNumeric() {
      if (_.isEmpty(this.dynamicTags) || _.isEmpty(this.testText)) {
        this.evaluatedResult = `<span></span>`;
        this.evaluatedStatus =
          `<span style="color: #f56c6c;">` +
          __("Test value or Rules cannot be empty") +
          `</span>`;
      } else {
        this.requestingHttpResponse = true;
        const testData = { rules: this.dynamicTags, test_text: this.testText };
        evaluateAlphaNumeric(testData)
          .then(res => {
            this.httpResponse = res.data;
            let result = "";
            let status = true;
            this.evaluatedStatus =
              `<span style="color: #67c23a;background: #f0f9eb;">` +
              __("Success:") +
              `</span>`;
            _.forEach(this.httpResponse, function(value) {
              let char = _.keys(value)[0];
              if (_.values(value)[0] === "pass") {
                result =
                  result +
                  `<strong style="color: #67c23a;background: #f0f9eb;">${char}</strong>`;
              } else {
                status = false;
                result =
                  result +
                  `<strong style="color: #f56c6c;background: #fef0f0;">${char}</strong>`;
              }
            });
            if (!status) {
              this.evaluatedStatus =
                `<span style="color: #f56c6c;background: #fef0f0;">` +
                __("Failed:") +
                `</span>`;
            }

            this.evaluatedResult = result;
          })
          .catch(err => {
            console.log(err);
          })
          .finally(() => {
            this.requestingHttpResponse = false;
          });
      }
    }
  },
  watch: {
    formType: {
      handler() {
        if (_.isEmpty(this.value) && this.formType === "Alpha Numeric") {
          this.$set(this.value, "rules", []);
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.phraseHintEditor {
  ::v-deep .editableContent {
    height: 200px;
    border-radius: 6px;
    border: 1px solid #a0a8b5;
    /*<!--box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2), 0 3px 4px 0 rgba(0, 0, 0, 0.14),-->*/
    /*<!--  0 3px 3px -2px rgba(0, 0, 0, 0.12);-->*/
    margin-left: 5px;
    outline: none;
    padding: 10px;
    overflow: auto;
  }
}
.json-path-result-row {
  /*background-color: black;*/
  /*color: white;*/
  font-size: 0.75rem;
  height: 25px;
  margin-top: 10px;
  border-radius: 3px;
  display: flex;
  align-items: center;
}

.json-path-result-container {
  display: flex;
  justify-content: flex-end;
}

.json-path-result {
  padding-right: 20px;
}
</style>
